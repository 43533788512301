<template>
  <div class="common-head">
    <div class="headLeft">
      <div class="headLogo flex-col"></div>
      <span class="title">
        运营数据中心
      </span>
    </div>
    <div class="headRight">
      <el-avatar :size="30" :src="userInfo.avatarUrl"></el-avatar>
      <el-dropdown @command="handleCommand">
        <span class="userName el-dropdown-link">
          {{ userInfo.userName }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item>个人空间</el-dropdown-item> -->
          <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      sfList: [],
      restaurants: [],
      province: '',
      state1: '',
      sfShow: false,
      userInfo: {}
    }
  },
  created() {
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo) {
      this.userInfo = userInfo
    }
  },
  methods: {
    //  退出登录
    async handleCommand(command) {
      console.log(command)
      if (command === 'loginOut') {
        let data = JSON.parse(localStorage.getItem('userInfo'))
        try {
          const { data: res } = await this.$api.loginOut({ token: data.token })
          if (res.code === 2000) {
            //localStorage.clear()
            localStorage.removeItem('userInfo')
            this.$router.push({ path: '/Login' })
          } else {
            this.$message.error(res.msg)
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.common-head {
  height: 60px;
  width: 100%;
  max-width: 2500px;
  margin: 0 auto;
  background-color: rgba(65, 127, 242, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headLeft {
    display: flex;
    justify-content: left;
    align-items: center;

    .headLogo {
      width: 113px;
      height: 60px;
      background: url(@/assets/operation/logo.png) center no-repeat;
      // background-size: 100% 100%;
      // margin-left:21px;
      margin-right: 20px;
    }

    .title {
      width: 209px;
      height: 24px;
      -webkit-text-stroke: 1px rgba(255, 255, 255, 1);
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      letter-spacing: 1px;
      // font-family: STSongti-SC;
      // font-weight: bold;
      text-align: left;
      white-space: nowrap;
      line-height: 24px;
      // margin: 18px 0 0 20px;
    }
  }

  .headRight {
    display: flex;
    justify-content: right;
    align-items: center;

    .userInfo {
      border-radius: 50%;
      background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/31685b4…_mergeImage.png);
      width: 30px;
      height: 30px;
      margin: 15px 0 0 909px;
    }

    .userName {
      width: 42px;
      height: 20px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      text-align: left;
      white-space: nowrap;
      line-height: 20px;
      margin: 20px 0 0 8px;
    }

    .el-dropdown-link {
      cursor: pointer;
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
}</style>
