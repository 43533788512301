// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 职教云统计
//课堂教学总览——课堂教学分布
export const zjySubStatisticsgetSubFaceTeach = params => {
  return request({
    url: '/api/zjySubStatistics/getSubFaceTeach',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂教学总览--院系活跃用户
export const zjySubStatisticsgetSubActivityUser = params => {
  return request ({
    url: '/api/zjySubStatistics/getSubActivityUser',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂教学总览--院系课程数量
export const zjySubStatisticsgetSubCourse = params => {
  return request ({
    url: '/api/zjySubStatistics/getSubCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂教学总览--院系资源分布
export const zjySubStatisticsgetSubResource = params => {
  return request ({
    url: '/api/zjySubStatistics/getSubResource',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂教学总览--院系到课率分布
export const zjySubStatisticsgetSubAttendance = params => {
  return request ({
    url: '/api/zjySubStatistics/getSubAttendance',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂教学总览--院系详情
export const zjySubStatisticsgetSubStatisticsList = params => {
  return request ({
    url: '/api/zjySubStatistics/getSubStatisticsList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂教学总览--导出院系详情
export const zjySubStatisticsexportSubStatisticsList = params => {
  return request ({
    url: '/api/zjySubStatistics/exportSubStatisticsList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
//教与学行为统计
export const zjyOverviewTotalDatagetTeachBehavior = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getTeachBehavior',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 基本数据

export const getZjyHeadTotalData = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getZjyHeadTotalData',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程建设总览
export const getCourseBuildData = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getCourseBuildData',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 周出勤率分析
export const getAttendanceWeek = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getAttendanceWeek',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 周课堂活动偏好分析
export const getClassActivity = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getClassActivity',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 月出勤率分析
export const getAttendanceMonth = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getAttendanceMonth',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 月课堂活动偏好分析
export const getClassActivityMonth = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getClassActivityMonth',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂教学数-活动数
export const getTeachData = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getTeachData',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 师生活动日志
export const getTeaStuLog = params => {
  return request({
    url: '/api/zjyOverviewTotalData/getTeaStuLog',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课程和班级统计
// 职教云课程分布
export const getZjyCourseSubStatistics = params => {
  return request({
    url: '/api/zjyCourse/getZjyCourseSubStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程数量统计
export const getCourseCount = params => {
  return request({
    url: '/api/zjyCourse/getCourseCount',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 按学生人数统计课程数量分布
export const getStudentCount = params => {
  return request({
    url: '/api/zjyCourse/getStudentCount',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 按活动数量统计课程数量分布
export const getFaceCount = params => {
  return request({
    url: '/api/zjyCourse/getFaceCount',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程资源分布
export const getCourseResource = params => {
  return request({
    url: '/api/zjyCourse/getCourseResource',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程出勤率分布统计
export const getCourseAttendance = params => {
  return request({
    url: '/api/zjyCourse/getCourseAttendance',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程考评分析
export const getCourseEvaluation = params => {
  return request({
    url: '/api/zjyCourse/getCourseEvaluation',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程详情列表
export const getZjyCourseList = params => {
  return request({
    url: '/api/zjyCourse/getZjyCourseList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程详情列表导出
export const exportZjyCourseList = params => {
  return request({
    url: '/api/zjyCourse/exportZjyCourseList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 课程查看
// 头部课程详情
export const getCourseData = params => {
  return request({
    url: '/api/zjyCourse/getCourseData',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源类型统计
export const getResourceType = params => {
  return request({
    url: '/api/zjyCourse/getResourceType',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源来源分布
export const getResourceCome = params => {
  return request({
    url: '/api/zjyCourse/getResourceCome',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程资源总量统计
export const getCourseResourceNum = params => {
  return request({
    url: '/api/zjyCourse/getCourseResourceNum',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 班级出勤率及通过率
export const getClassAttendance = params => {
  return request({
    url: '/api/zjyCourse/getClassAttendance',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 班级活动数
export const getClassActivity2 = params => {
  return request({
    url: '/api/zjyCourse/getClassActivity',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 班级列表
export const getClassList = params => {
  return request({
    url: '/api/zjyCourse/getClassList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 班级列表导出
export const exportClassList = params => {
  return request({
    url: '/api/zjyCourse/exportClassList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 班级详情
// 头部详情
export const getClassCourse = params => {
  return request({
    url: '/api/ZjyCourseClass/getClassCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 到课率分布
export const getClassAttendance1 = params => {
  return request({
    url: '/api/ZjyCourseClass/getClassAttendance',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 平均到课率
export const getClassAvgAttendance = params => {
  return request({
    url: '/api/ZjyCourseClass/getClassAvgAttendance',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂活动偏好
export const getClassFaceTeachActivityDistribution = params => {
  return request({
    url: '/api/ZjyCourseClass/getClassFaceTeachActivityDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 成绩分布，资源浏览完成度分布
export const getClassStuScoreDistribution = params => {
  return request({
    url: '/api/ZjyCourseClass/getClassStuScoreDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源浏览完成度分布
export const GetClassStuCourseCellFinishPercentDistribution = params => {
  return request({
    url: '/api/ZjyCourseClass/GetClassStuCourseCellFinishPercentDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂活动日历
export const getFaceTeachCalendar = params => {
  return request({
    url: '/api/ZjyCourseClass/getFaceTeachCalendar',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 班级学生信息
export const getClassStuInfoList = params => {
  return request({
    url: '/api/ZjyCourseClass/getClassStuInfoList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 班级学生信息导出
export const exportClassStuInfoList = params => {
  return request({
    url: '/api/ZjyCourseClass/exportClassStuInfoList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 行为分析
// 教师课程建设数量分布
export const getSPOCCourse1 = params => {
  return request({
    url: '/api/ZjyTeacher/getSPOCCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 教师授课班级数量分布
export const getSPOCTeaching = params => {
  return request({
    url: '/api/ZjyTeacher/getSPOCTeaching',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 教师课程活动数量分布
export const getClassActivity1 = params => {
  return request({
    url: '/api/ZjyTeacher/getClassActivity',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 教学行为
export const getTeachAction = params => {
  return request({
    url: '/api/ZjyTeacher/getTeachAction',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 教学行为导出
export const exportTeachAction = params => {
  return request({
    url: '/api/ZjyTeacher/exportTeachAction',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 成绩统计
// 学生成绩列表
export const getStudentScore = params => {
  return request({
    url: '/api/scoreManagement/getStudentScore',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 学生成绩列表导出
export const exportStudentScoreList = params => {
  return request({
    url: '/api/scoreManagement/exportStudentScoreList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 课程成绩列表
export const getCourseScore = params => {
  return request({
    url: '/api/scoreManagement/getCourseScore',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程成绩列表导出
export const exportCourseScoreList = params => {
  return request({
    url: '/api/scoreManagement/exportCourseScoreList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 课程列表
export const getCourse = params => {
  return request({
    url: '/api/scoreManagement/getCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 班级列表
export const getClassName = params => {
  return request({
    url: '/api/scoreManagement/getClassName',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 排行榜
// 活跃教师top10
export const getZjyTeaTop = params => {
  return request({
    url: '/api/ranking/getZjyTeaTop',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 班级出勤率top10
export const getZjyStuTop = params => {
  return request({
    url: '/api/ranking/getZjyStuTop',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 活跃学生top10
export const getZjyClassRate = params => {
  return request({
    url: '/api/ranking/getZjyClassRate',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 职教云活跃课程top10
export const getSpocCourseTop = params => {
  return request({
    url: '/api/ranking/getSpocCourseTop',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 导出职教云数据概览
export const exportZjyHeadTotalData = params => {
  return request({
    url: '/api/zjyOverviewTotalData/exportZjyHeadTotalData',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 导出职教云教学行为统计
export const exportTeachBehavior = params => {
  return request({
    url: '/api/zjyOverviewTotalData/exportTeachBehavior',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 导出职教云课堂教学总览
export const exportSubFaceTeach = params => {
  return request({
    url: '/api/zjySubStatistics/exportSubFaceTeach',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}




