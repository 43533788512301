import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 校级侧边菜单
    schoolMenuList: [
      {
        label: "数据总览",
        children: [
          {
            label: "总体数据",
            path: "/school/dashBoard",
            icon: require("@/assets/school/menuIcon/icon_01_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_01_on.png"),
          },
          {
            label: "影响力",
            path: "/school/effect",
            icon: require("@/assets/school/menuIcon/icon_02_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_02_on.png"),
          },
        ],
      },
      {
        label: "师生信息",
        children: [
          {
            label: "教师统计",
            path: "/school/TeacherStatistics",
            icon: require("@/assets/school/menuIcon/icon_03_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_03_on.png"),
          },
          {
            label: "学生统计",
            path: "/school/StudentStatistics",
            icon: require("@/assets/school/menuIcon/icon_04_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_04_on.png"),
          },
        ],
      },
      {
        label: "院系统计",
        path: "/school/CollegeStatistics",
        icon: require("@/assets/school/menuIcon/icon_05_off.png"),
        iconA: require("@/assets/school/menuIcon/icon_05_on.png"),
      },
      {
        label: "资源库统计",
        children: [
          {
            label: "专业建设",
            path: "/school/ProfessionalConstruction",
            icon: require("@/assets/school/menuIcon/icon_06_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_06_on.png"),
          },
          {
            label: "资源建设",
            path: "/school/ResourceConstruction",
            icon: require("@/assets/school/menuIcon/icon_07_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_07_on.png"),
          },
          {
            label: "课程建设",
            path: "/school/CurriculumConstruction",
            icon: require("@/assets/school/menuIcon/icon_08_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_08_on.png"),
          },
          {
            label: "排行榜",
            path: "/school/zykRankingList",
            icon: require("@/assets/school/menuIcon/icon_09_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_09_on.png"),
          },
          {
            label: "课程调用",
            path: "/school/CourseCall",
            icon: require("@/assets/school/menuIcon/icon_10_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_10_on.png"),
          },
        ],
      },
      {
        label: "职教云统计",
        children: [
          {
            label: "基本数据",
            path: "/school/zjyData",
            icon: require("@/assets/school/menuIcon/icon_11_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_11_on.png"),
          },
          {
            label: "院系统计",
            path: "/school/yxStatistics",
            icon: require("@/assets/school/menuIcon/icon_12_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_12_on.png"),
          },
          {
            label: "课堂教学统计",
            path: "/school/classteachStatistics",
            icon: require("@/assets/school/menuIcon/icon_12_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_12_on.png"),
          },
          {
            label: "课程和班级统计",
            path: "/school/CCStatistics",
            icon: require("@/assets/school/menuIcon/icon_12_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_12_on.png"),
          },
          {
            label: "行为分析",
            path: "/school/zjyBehaviorAnalysis",
            icon: require("@/assets/school/menuIcon/icon_13_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_13_on.png"),
          },
          {
            label: "成绩统计",
            path: "/school/AchievementStatistics",
            icon: require("@/assets/school/menuIcon/icon_14_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_14_on.png"),
          },
          {
            label: "排行榜",
            path: "/school/zjyRankingList",
            icon: require("@/assets/school/menuIcon/icon_09_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_09_on.png"),
          },
        ],
      },
      {
        label: "MOOC统计",
        children: [
          {
            label: "基本数据",
            path: "/school/MOOCData",
            icon: require("@/assets/school/menuIcon/icon_11_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_11_on.png"),
          },
          {
            label: "课程统计",
            path: "/school/MOOCCourseStatistics",
            icon: require("@/assets/school/menuIcon/icon_15_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_15_on.png"),
          },
          {
            label: "排行榜",
            path: "/school/MOOCRankingList",
            icon: require("@/assets/school/menuIcon/icon_09_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_09_on.png"),
          },
        ],
      },
      {
        label: "专业群统计",
        isShow: true,
        children: [
          {
            label: "数据预览",
            path: "/school/zyqData",
            iszyq: true,
            icon: require("@/assets/school/menuIcon/icon_16_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_16_on.png"),
          },
          {
            label: "资源统计",
            path: "/school/ResourcesStatistics",
            iszyq: true,
            icon: require("@/assets/school/menuIcon/icon_17_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_17_on.png"),
          },
          {
            label: "题库统计",
            path: "/school/BankStatistics",
            iszyq: true,
            icon: require("@/assets/school/menuIcon/icon_18_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_18_on.png"),
          },
          {
            label: "课程统计",
            path: "/school/zyqCourseStatistics",
            iszyq: true,
            icon: require("@/assets/school/menuIcon/icon_15_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_15_on.png"),
          },
          {
            label: "用户详情",
            path: "/school/zyqUserDetails",
            iszyq: true,
            icon: require("@/assets/school/menuIcon/icon_19_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_19_on.png"),
          },
          {
            label: "日志统计",
            path: "/school/zyqLogStatistics",
            iszyq: true,
            icon: require("@/assets/school/menuIcon/icon_20_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_20_on.png"),
          },
        ],
      },
      {
        label: "预警和排名",
        children: [
          {
            label: "考勤预警",
            path: "/school/AttendanceWarning",
            icon: require("@/assets/school/menuIcon/icon_21_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_21_on.png"),
          },
          {
            label: "成绩预警",
            path: "/school/PerformanceWarning",
            icon: require("@/assets/school/menuIcon/icon_22_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_22_on.png"),
          },
          {
            label: "教师行为预警",
            path: "/school/TeacherBehaviorWarning",
            icon: require("@/assets/school/menuIcon/icon_23_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_23_on.png"),
          },
          {
            label: "学生行为预警",
            path: "/school/StudentBehaviorWarning",
            icon: require("@/assets/school/menuIcon/icon_24_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_24_on.png"),
          },
          {
            label: "课程质量分析",
            path: "/school/CourseQualityAnalysis",
            icon: require("@/assets/school/menuIcon/icon_25_off.png"),
            iconA: require("@/assets/school/menuIcon/icon_25_on.png"),
          },
        ],
      },
      {
        label: "学分认证",
        path: "/school/CreditCertification",
        icon: require("@/assets/school/menuIcon/icon_26_off.png"),
        iconA: require("@/assets/school/menuIcon/icon_26_on.png"),
      },
      {
        label: "实时大屏",
        path: "/school/RealtimeLargeScreen",
        icon: require("@/assets/school/menuIcon/icon_27_off.png"),
        iconA: require("@/assets/school/menuIcon/icon_27_on.png"),
      },
    ],
    // 省级侧边菜单
    provinceMenuList: [
      {
        label: "数据概览",
        path: "/province/dashBoard",
        icon: require("@/assets/province/navIcon.png"),
        iconA: require("@/assets/province/navIcona.png"),
      },
      {
        label: "排行榜统计",
        icon: require("@/assets/province/phbtj.png"),
        iconA: require("@/assets/province/phtja.png"),
        children: [
          {
            label: "学院综合应用排行榜",
            path: "/province/RankingStatistics",
            icon: require("@/assets/province/navIcon1.png"),
            iconA: require("@/assets/province/navIcon1a.png"),
          },
          {
            label: "资源贡献排行榜",
            path: "/province/ResourceContribution",
            icon: require("@/assets/province/zygx.png"),
            iconA: require("@/assets/province/zygxa.png"),
          },
        ],
      },
      {
        label: "院校统计",
        path: "/province/CollegeStatistics",
        icon: require("@/assets/province/navIcon2.png"),
        iconA: require("@/assets/province/navIcon2a.png"),
      },
      {
        label: "课程统计",
        path: "/province/CourseStatistics",
        icon: require("@/assets/province/navIcon3.png"),
        iconA: require("@/assets/province/navIcon3a.png"),
      },
      {
        label: "用户统计",
        path: "/province/UserStatistics",
        icon: require("@/assets/province/navIcon4.png"),
        iconA: require("@/assets/province/navIcon4a.png"),
      },
      {
        label: "教学行为日志统计",
        icon: require("@/assets/province/jxxw.png"),
        iconA: require("@/assets/province/jxxwa.png"),
        children: [
          // {
          //   label: '专业群数据',
          //   path: '/province/ProfessionalGroup',
          //   icon: require('@/assets/province/tjfxIcon1.png'),
          //   iconA: require('@/assets/province/tjfxIcon1a.png')
          // },
          {
            label: "资源库建设",
            path: "/province/ResourceLibrary",
            icon: require("@/assets/province/tjfxIcon2.png"),
            iconA: require("@/assets/province/tjfxIcon2a.png"),
          },
          {
            label: "课堂教学概况",
            path: "/province/OverviewTeaching",
            icon: require("@/assets/province/tjfxIcon3.png"),
            iconA: require("@/assets/province/tjfxIcon3a.png"),
          },
          {
            label: "活动日志统计",
            path: "/province/ActivityLogStatistics",
            icon: require("@/assets/province/tjfxIcon4.png"),
            iconA: require("@/assets/province/tjfxIcon4a.png"),
          },
        ],
      },
      // {
      //   label: '预警',
      //   path: '/province/earlyWarning',
      //   icon: require('@/assets/province/yjm.png'),
      //   iconA: require('@/assets/province/yjzt.png')
      // }
    ],
    province: "",
    // 运营侧边菜单
    operationMenuList: [
      {
        label: "数据概览",
        path: "/operation/dashBoard",
        icon: require("@/assets/operation/navIcon.png"),
        iconA: require("@/assets/operation/navIcona.png"),
      },
      {
        label: "用户统计",
        path: "/operation/UserStatistics",
        icon: require("@/assets/operation/navIcon2.png"),
        iconA: require("@/assets/operation/navIcon2a.png"),
      },
      {
        label: "素材统计",
        path: "/operation/MaterialStatistics",
        icon: require("@/assets/operation/navIcon3.png"),
        iconA: require("@/assets/operation/navIcon3a.png"),
      },
      {
        label: "题库统计",
        path: "/operation/QuestionStatistics",
        icon: require("@/assets/operation/navIcon4.png"),
        iconA: require("@/assets/operation/navIcon4a.png"),
      },
      {
        label: "课程统计",
        path: "/operation/CourseStatistics",
        icon: require("@/assets/operation/navIcon5.png"),
        iconA: require("@/assets/operation/navIcon5a.png"),
      },
      {
        label: "资源调用",
        path: "/operation/ResourceCall",
        icon: require("@/assets/operation/navIcon6.png"),
        iconA: require("@/assets/operation/navIcon6a.png"),
      },
      {
        label: "新增详情",
        path: "/operation/NewDetails",
        icon: require("@/assets/operation/navIcon7.png"),
        iconA: require("@/assets/operation/navIcon7a.png"),
      },
      {
        label: "日志统计",
        path: "/operation/ActivityLogStatistics",
        icon: require("@/assets/operation/navIcon8.png"),
        iconA: require("@/assets/operation/navIcon8a.png"),
      },
    ],
    // 校级导航路由
    schoolBreadcrumbList: [
      {
        label: "总体数据",
        path: "/school/dashBoard",
        id: 0,
      },
      {
        label: "影响力",
        path: "/school/effect",
        id: 1,
      },
      {
        label: "用户校园分布详情",
        path: "/school/DistributionDetails",
        partherId: 1,
        id: 2,
      },
      {
        label: "教师统计",
        path: "/school/TeacherStatistics",
        id: 3,
      },
      {
        label: "教师画像",
        path: "/school/TeacherPortrait",
        partherId: 3,
        id: 5,
      },
      {
        label: "资源库课程数据",
        path: "/school/zykCourseData",
        partherId: 5,
        id: 6,
      },
      {
        label: "SPOC课程数据",
        path: "/school/SPOCCourseData",
        partherId: 5,
        id: 7,
      },
      {
        label: "课堂与教学数据",
        path: "/school/ClassroomTeaching",
        partherId: 5,
        id: 8,
      },
      {
        label: "课堂教学班级数据",
        path: "/school/ClassroomTeachingClass",
        partherId: 5,
        id: 9,
      },
      {
        label: "MOOC学院课程数据",
        path: "/school/MOOCCourseData",
        partherId: 5,
        id: 10,
      },
      {
        label: "学生统计",
        path: "/school/StudentStatistics",
        id: 4,
      },
      {
        label: "学生画像",
        path: "/school/StudentPortrait",
        partherId: 4,
        id: 11,
      },
      {
        label: "院系统计",
        path: "/school/CollegeStatistics",
        id: 12,
      },
      {
        label: "专业建设",
        path: "/school/ProfessionalConstruction",
        id: 13,
      },
      {
        label: "教师资源建设详情",
        path: "/school/teacherResourceConstruction",
        partherId: 13,
        id: 18,
      },
      {
        label: "资源建设",
        path: "/school/ResourceConstruction",
        id: 14,
      },
      {
        label: "资源建设详情",
        path: "/school/ResourceConstructionDetails",
        partherId: 14,
        id: 19,
      },
      {
        label: "课程建设",
        path: "/school/CurriculumConstruction",
        id: 15,
      },
      {
        label: "课程详情",
        path: "/school/zykCourseDetails",
        partherId: 15,
        id: 20,
      },
      {
        label: "排行榜",
        path: "/school/zykRankingList",
        id: 16,
      },
      {
        label: "课程调用",
        path: "/school/CourseCall",
        id: 17,
      },
      {
        label: "基本数据",
        path: "/school/zjyData",
        id: 21,
      },
      {
        label: "课程和班级统计",
        path: "/school/CCStatistics",
        id: 25,
      },
      {
        label: "院系统计",
        path: "/school/yxStatistics",
        id: 155,
      },
      {
        label: "课堂教学统计",
        path: "/school/classteachStatistics",
        id: 156,
      },
      {
        label: "课程详情",
        path: "/school/zjyCourseDetails",
        partherId: 25,
        id: 26,
      },
      {
        label: "班级详情",
        path: "/school/zjyClasseDetails",
        partherId: 26,
        id: 27,
      },
      {
        label: "学生详情",
        path: "/school/zjyStudentDetails",
        partherId: 27,
        id: 28,
      },
      {
        label: "行为分析",
        path: "/school/zjyBehaviorAnalysis",
        id: 22,
      },
      {
        label: "成绩统计",
        path: "/school/AchievementStatistics",
        id: 23,
      },
      {
        label: "排行榜",
        path: "/school/zjyRankingList",
        id: 24,
      },
      {
        label: "基本数据",
        path: "/school/MOOCData",
        id: 29,
      },
      {
        label: "课程统计",
        path: "/school/MOOCCourseStatistics",
        id: 30,
      },
      {
        label: "课程详情",
        path: "/school/MOOCCourseDetails",
        partherId: 30,
        id: 32,
      },
      {
        label: "排行榜",
        path: "/school/MOOCRankingList",
        id: 31,
      },
      {
        label: "数据预览",
        path: "/school/zyqData",
        id: 32,
      },
      {
        label: "资源统计",
        path: "/school/ResourcesStatistics",
        id: 33,
      },
      {
        label: "按课程统计",
        path: "/school/CourseResourcesStatistics",
        partherId: 33,
        id: 38,
      },
      {
        label: "题库统计",
        path: "/school/BankStatistics",
        id: 34,
      },
      {
        label: "按课程统计",
        path: "/school/CourseBankStatistics",
        partherId: 34,
        id: 39,
      },
      {
        label: "课程统计",
        path: "/school/zyqCourseStatistics",
        id: 35,
      },
      {
        label: "课程详情",
        path: "/school/zyqCourseDetails",
        partherId: 35,
        id: 40,
      },
      {
        label: "用户详情",
        path: "/school/zyqUserDetails",
        id: 36,
      },
      {
        label: "按院校分布列表",
        path: "/school/zyqUserDetailsList",
        partherId: 36,
        id: 41,
      },
      {
        label: "日志统计",
        path: "/school/zyqLogStatistics",
        id: 37,
      },
      {
        label: "用户行为记录",
        path: "/school/UserBehaviorRecord",
        partherId: 37,
        id: 42,
      },
      {
        label: "考勤预警",
        path: "/school/AttendanceWarning",
        id: 43,
      },
      {
        label: "成绩预警",
        path: "/school/PerformanceWarning",
        id: 44,
      },
      {
        label: "教师行为预警",
        path: "/school/TeacherBehaviorWarning",
        id: 45,
      },
      {
        label: "学生行为预警",
        path: "/school/StudentBehaviorWarning",
        id: 46,
      },
      {
        label: "课程质量分析",
        path: "/school/CourseQualityAnalysis",
        id: 47,
      },
      {
        label: "学分认证",
        path: "/school/CreditCertification",
        id: 48,
      },
      {
        label: "实时大屏",
        path: "/school/RealtimeLargeScreen",
        icon: require("@/assets/school/menuIcon/icon_27_off.png"),
        iconA: require("@/assets/school/menuIcon/icon_27_on.png"),
        id: 49,

      },
    ],
    //权限设置路由
    authorityMenuList: [
      // {
      //   label: "角色管理",
      //   path: "/authority/roleAdmin",
      // },
      // {
      //   label: "用户管理",
      //   path: "/authority/userAdmin",
      // },
      // {
      //   label: "校级数据中心管理",
      //   path: "/authority/schoolAdmin",
      // },
      // {
      //   label: "菜单管理",
      //   path: "/authority/menu",
      // },
    ],
    selectRightIdList: [],
    routerLink: [],
    routerShow: false,
    schoolManage: '',
    userManage: '',
    center: [107.4, 33.42],
    wallName: '中国'
  },
  getters: {},
  mutations: {
    SET_CENTER: (state, center) => {
      state.center = center
      console.log('state.center',state.center);
    },
    CHANGE_SCHMENU(state, n) {
      // console.log(n,'数据时间')
      // state.schoolMenuList[6].isShow = n;
    },
    ROUTER_LIST(state, data) {
      state.operationMenuList = data.operationMenuList
      state.schoolMenuList = data.schoolBreadcrumbList
      state.provinceMenuList = data.provinceMenuList
      state.routerLink = data.routerLink
      state.authorityMenuList = data.authorityMenuList
      state.routerShow = true
    },
    CHANGE_schoolManage(state, schoolManage) {
      state.schoolManage = schoolManage
    },
    CHANGE_userManage(state, userManage) {
      state.userManage = userManage
    },
    CHANGE_selectRightIdList(state, selectRightIdList) {
      state.selectRightIdList = selectRightIdList
    }
  },
  actions: {
    routerList({ commit }, data) {
      commit('ROUTER_LIST', data)
    },
    change_schmenu({ commit }, data) {
      // console.log(data,'skjg')
      commit('CHANGE_SCHMENU', data)
    }
  },
  modules: {},
});
