<template>
  <div class="common-head">
    <div class="headLeft">
      <div class="headLogo flex-col"></div>
      <span class="title">
        校级数据中心
        <span class="schooltab" v-if='isDemo'>演示学校</span>
        <span class="schooltab" @click="sfShows" v-else v-show="!sfShow" >
          {{ school }}
          <i class="el-icon-caret-bottom"></i>
        </span>
      </span>
      <span v-show="sfShow" class="sfSelect">
        <div>
          <!-- <el-input placeholder="选择或输入省级数据中心" type="text" name="" v-model="state1"></el-input> -->
          <el-autocomplete class="inline-input" v-model="query.schoolName" ref="inputRef"
            :fetch-suggestions="querySearch" placeholder="选择或输入搜索切换校级数据中心" :popper-append-to-body="false"
            @select="handleSelect" @keyup="changeStyle('block', '.el-autocomplete-suggestion')" @blur="inputBlur">
            <template slot-scope="{ item }">
              <div v-if="!!item.value" class="name">{{ item.value }}</div>
            </template>
          </el-autocomplete>
          <i class="el-icon-caret-top title1" @click="inputBlur"></i>
        </div>
      </span>
    </div>
    <div class="headRight">
      <el-popover placement="top-start" width="400" trigger="hover" class="popover" :append-to-body="false"
        v-if="isShowBtn">
        <div class="line1">
          <div class="button">{{ version }}</div>
          <div class="endTime">{{ endTime }}到期</div>
        </div>
        <div class="line2">
          <p>若想继续使用{{ version }}功能, <span @click="showDialog">请联系智慧职教相关人员</span></p>
        </div>
        <el-button slot="reference" class="data_btn" v-if="expire > 0"><span>{{ expire }}</span> 天到期</el-button>
        <el-button slot="reference" class="data_btn" v-else-if="expire < 0">已过期 <span>{{ Math.abs(expire) }} 天</span></el-button>
        <el-button slot="reference" class="data_btn" v-else-if="expire == 0">今天到期</el-button>

      </el-popover>
      <el-avatar :size="30" :src="userInfo.avatarUrl"></el-avatar>
      <el-dropdown @command="handleCommand">
        <span class="userName el-dropdown-link">
          {{ userInfo.userName
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item>个人空间</el-dropdown-item> -->
          <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="700px" :before-close="handleClose" class="dialog">
      <p>当前数据中心为{{ version }}, 版本到期时间为<span>{{endTime}}</span>, 版本到期后无法使用{{version}}相关功能，若想继续使用{{version}}功能，请联系智慧职教相关人员</p>
      <h1>智慧职教相关人员联系方式</h1>
      <div class="item" v-for="(item, j) in teacerArr" :key="j">
        <h2>{{item.name}}</h2>
        <p>{{ item.adress }}</p>
        <div class="phone">
          <div class="phone1"><i class="el-icon-phone"></i>{{ item.phone1 }}</div>
          <div class="phone2"><i class="el-icon-mobile-phone"></i>{{ item.phone2 }}</div>
          <div class="email"> <i class="el-icon-message"></i>{{ item.email }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDemo: false,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      schoolList: [],
      restaurants: [],
      isShowBtn: false,
      school: "",
      expire: '',
      startTime: '',
      dialogVisible: false,
      endTime: '',
      expire: '',
      version: '',
      teacerArr: [
        {
          name: '王老师',
          adress: '陕、新、甘青宁、粤、川、渝、贵、滇、桂、晋、湘、琼',
          phone1: '010-58582375',
          phone2: '13911796107',
          email: 'wangfan@hep.com.cn'
        },
        {
          name: '陈老师',
          adress: '京、鲁、豫、津、冀、内、鄂、赣',
          phone1: '010-58556413',
          phone2: '13466793879',
          email: 'chenwen1@hep.com.cn'
        },
        {
          name: '王老师',
          adress: '黑、吉、辽、沪、浙、苏、皖、闽',
          phone1: '010-58556570',
          phone2: '13601267197',
          email: 'wangbo@hep.com.cn'
        },
      ],
      query: {
        schoolName: "",
      },
      value: "",
      state1: "",
      sfShow: false,
      userInfo: {},
      timeout: "",
    };
  },
  created() {
    this.isDemo = JSON.parse(localStorage.getItem('userInfo')).isDemo;
    console.log(this.$route.query, '测试');
    if (this.$route.query.id) {
      this.school = this.$route.query.schoolName
      sessionStorage.setItem("schoolName", this.school);
      sessionStorage.setItem("schoolId", this.$route.query.id);
      this.getSchools()
    }
    this.getSchools();
    if (sessionStorage.getItem("schoolName")) {
      this.school = sessionStorage.getItem("schoolName");
      this.getschoolVersion()
      // console.log(this.school,'sdfdsdf')
    } else {
      // sessionStorage.setItem('schoolId', '6ozvawkndp1g5kz2s-peza')
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.userInfo = userInfo;
    }
  },
  mounted() {
    
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    //  退出登录
    async handleCommand(command) {
      console.log(command);
      if (command === "loginOut") {
        let data = JSON.parse(localStorage.getItem("userInfo"));
        try {
          const { data: res } = await this.$api.loginOut({ token: data.token });
          if (res.code === 2000) {
            //localStorage.clear()
            localStorage.removeItem("userInfo");
            sessionStorage.removeItem("schoolId")
            sessionStorage.removeItem("sfprovince")
            sessionStorage.removeItem("schoolName")
            sessionStorage.removeItem("pgId")
            this.$router.push({ path: "/Login" });
          } else {
            this.$message.error(res.msg);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    // toggleMenu () {
    //   this.$store.commit('nav/TOGGLE_MENU')
    // },
    showDialog() {
      this.dialogVisible = true
    },
    // 请求院校
    async getschoolVersion() {
      try {
        const { data: res } = await this.$api.getSchoolVersion({
          schoolId: sessionStorage.getItem("schoolId")
        })
        if (res.code == 2000) {
          this.version = res.data.version == 1 ? '正式版' : '体验版'
          if (res.data.schoolStartTime === null || res.data.schoolEndTime === null) {
            this.isShowBtn = false
          } else {
            this.isShowBtn = true
          }
          this.startTime = res.data.schoolStartTime
          this.endTime = res.data.schoolEndTime
          var targetDate = new Date(this.endTime.replace(' ', 'T'))
          var currentDate = new Date()
          var differenceInMs = targetDate - currentDate
          if(Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)) > 0) {
            this.expire = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24))
            this.text = '天到期'
          } else if(Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)) < 0) {
            this.expire = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24))
            this.text = '已过期'
          } else {
            this.expire = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24))
            this.text= '今天到期'
          }
          

        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSchools() {
      console.log(111);
      try {
        const { data: res } = await this.$api.getSchools(this.query);

        if (res.code === 2000) {
          if (res.data && res.data.length > 0) {
            this.schoolList = [...res.data];
          } else {
            this.schoolList = [{ name: "无匹配数据", id: "" }];
          }
          console.log("this.schoolList", this.schoolList);
          this.resChange();
        } else {
          this.$message.error("您没有登录");
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 切换院校
    sfShows() {
      this.sfShow = true;
      this.getSchools();
      this.inputFocus();
    },
    // 院校列表
    resChange() {
      this.restaurants = [
        ...this.schoolList.map((item) => {
          return {
            value: item.name || "",
            id: item.id || "",
          };
        }),
      ];
      this.restaurants.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });
      // console.log(this.schoolList[0].name,'数据ss')
      if (!this.school) {
        this.school = this.restaurants[0].value;
        sessionStorage.setItem("schoolName", this.school);
        sessionStorage.setItem("schoolId", this.restaurants[0].id);
        this.$router.go(0);
      }
      // console.log(this.schoolList)
      // console.log(this.restaurants)
    },
    // 获取光标
    inputFocus() {
      setTimeout(() => {
        this.$refs.inputRef.$children[0].focus();
      }, 10);
    },
    // 失去光标触发
    inputBlur(e) {
      setTimeout(() => {
        // this.changeStyle('none', '.el-autocomplete-suggestion')
        this.sfShow = false;
      }, 250);
    },
    // 远程获取学校列表
    querySearch(queryString, cb) {
      console.log(queryString, 'queryString111');
      if (queryString == "") {
        cb(this.restaurants);
      } else {
        this.query.schoolName = queryString
        this.getSchools();
        setTimeout(() => {
          cb(this.restaurants);
        }, 1000)
      }
      // console.log(restaurants)
      // 调用 callback 返回建议列表的数据
      // console.log(restaurants); 
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toUpperCase().match(queryString.toUpperCase());
      };
    },
    handleSelect(item) {
      // console.log(item)
      if (item.id !== "") {
        sessionStorage.setItem("schoolId", item.id);
        sessionStorage.setItem("schoolName", item.value);
        sessionStorage.removeItem("teaId");
        sessionStorage.removeItem("stuId");
        sessionStorage.removeItem("courseId");
        sessionStorage.removeItem("classId");
        // console.log(1111);
        this.school = item.value;
      }
      this.sfShow = false;
      // this.changeStyle('none', '.el-autocomplete-suggestion')
      this.query.schoolName = "";
      this.getschoolVersion()
      // this.state1 = ''
      // this.$router.go(0)
      this.$emit("gobreak");
    },
    // 根据传进来的状态改变建议输入框的状态（展开|隐藏）
    changeStyle(status, className) {
      const dom = document.querySelectorAll(className);
      dom[0].style.display = status;
    },
    changeInput() {
      this.getSchools();
    },
    clickMenuItem(action) {
      if (action === "logout") {
        this.$store.commit("user/CLEAR_USER_INFO");
        this.$router.go(0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  p {
    line-height: 30px;
    span {
      margin: 0 5px;
      color: rgba(65, 127, 242, 1);
    }
  }
  h1 {
    margin: 20px 0;
    text-align: center;
  }
  .item {
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgb(153,153,153);
    p {
      color: rgb(153,153,153);
    }
    .phone {
      div {
        i {
          margin-right: 5px;
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.common-head {
  height: 60px;
  width: 100%;
  max-width: 2500px;
  margin: 0 auto;
  background-color: rgba(65, 127, 242, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headLeft {
    display: flex;
    justify-content: left;
    align-items: center;
    flex: 1;

    .headLogo {
      width: 113px;
      height: 60px;
      background: url(@/assets/province/logo.png) center no-repeat;
      // background-size: 100% 100%;
      // margin-left:21px;
      margin-right: 20px;
    }

    .title {
      width: 209px;
      height: 24px;
      -webkit-text-stroke: 1px rgba(255, 255, 255, 1);
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      letter-spacing: 1px;
      // font-family: STSongti-SC;
      // font-weight: bold;
      text-align: left;
      white-space: nowrap;
      line-height: 24px;

      // margin: 18px 0 0 20px;
      .schooltab {
        margin-left: 40px;
        font-size: 18px;
        font-weight: 300;
        color: #ffffff;
        line-height: 21px;
      }

      .el-dropdown {
        font-size: 24px;
        color: #fff;
      }
    }

    .title1 {
      // width: 209px;
      font-weight: normal;
      height: 24px;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      margin-left: 10px;
    }

    .sfSelect {
      width: 300px;
    }
  }

  .headRight {
    display: flex;
    justify-content: right;
    align-items: center;

    .popover {
      .line1 {
        display: flex;
        justify-content: start;
        margin-bottom: 10px;

        .button {
          text-align: center;
          width: 60px;
          background-color: rgb(65, 127, 242);
          height: 20px;
          border-radius: 30px;
          border-bottom-left-radius: 0;
          color: #ffffff;
          margin-right: 5px;
        }
      }

      .line2 {
        span {
          cursor: pointer;
          color: rgb(65, 127, 242);
        }
      }
    }

    .data_btn {
      margin-right: 30px;
      height: 30px;
      width: 120px;
      padding: 0;
      line-height: 30px;
      color: rgb(65, 127, 242)
    }

    .userInfo {
      border-radius: 50%;
      background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/31685b4…_mergeImage.png);
      width: 30px;
      height: 30px;
      margin: 15px 0 0 909px;
    }

    .userName {
      width: 42px;
      height: 20px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      text-align: left;
      white-space: nowrap;
      line-height: 20px;
      margin: 20px 0 0 8px;
    }

    .el-dropdown-link {
      cursor: pointer;
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
}
</style>
<style lang="scss">
// 输入下拉框提示
.el-autocomplete-suggestion ul li {
  line-height: 40px;
  font-size: 20px;
}

.el-autocomplete-suggestion {
  width: auto !important;
  min-width: 200px;
}
</style>
